/**
 * scroll-top
 */

import anime from 'animejs';
import Component from 'js/lib/component';

export default class Totop extends Component {
  init() {}
  events() {
    this.$.pageWrapper.parent().on('scroll', () => {
      const offset = this.$.pageWrapper.get(0).getBoundingClientRect();

      if (offset.y < -100) {
        this.$.root.css('opacity', 1);
      } else {
        this.$.root.css('opacity', 0);
      }
    });

    this.$.root.on('click', () => {
      const $wrapper = this.$.pageWrapper;
      const $parent = $wrapper.parent();
      const hasCustomScrollbar = $parent.is('.optiscroll-content');
      const $target = hasCustomScrollbar ? $parent : $wrapper;

      anime({
        targets: $target.get(0),
        scrollTop: 0,
        easing: 'easeInOutCubic',
        duration: 1000
      });

      return false;
    });

    // this.checkHeight();
  }
  checkHeight() {
    const pageHeight = this.$.pageWrapper.get(0).scrollHeight;
    const windowHeight = this.$.window.height();

    if (pageHeight <= windowHeight) {
      this.removeRoot();
    } else {
      this.$.root.css('opacity', 1);
    }
  }
}

Component.mount(Totop, '.js-totop');
