import $ from 'jquery';
import Component from 'js/lib/component';

export default class Share extends Component {
  init() {
    this.classList = {
      VK: '.js-share-vk',
      FACEBOOK: '.js-share-facebook',
      OK: '.js-share-odnoklassniki',
      TWITTER: '.js-share-twitter'
    };
  }
  events() {
    const { title, href } = this.getParams();

    this.$.document

      // VK
      .on('click', this.classList.VK, () => {
        const winTop = (window.screen.height - 620) / 2;
        const winLeft = (window.screen.width - 660) / 2;
        const url = `//vk.com/share.php?url=${href}&tltl=${title}&cmg=1`;

        window.open(
          url,
          'shareSite',
          `width=550px,height=268px,resizable=yes,scrollbars=yes,left=${winLeft},top=${winTop}`
        );
      })

      // Twitter
      .on('click', this.classList.TWITTER, () => {
        const url = `//twitter.com/share?text=${title}`;
        window.open(url);
      })

      // Facebook
      .on('click', this.classList.FACEBOOK, () => {
        const url = `//www.facebook.com/sharer.php?u=${href}&title=${title}`;
        window.open(url);
      })

      // Odnoklasniki
      .on('click', this.classList.OK, () => {
        const url = `//www.odnoklassniki.ru/dk?st.cmd=addShare&st.s=1&st._surl=${href}&st.comments=${title}`;
        window.open(url);
      });
  }
  getParams() {
    return {
      title: document.title,
      href: document.location.href
    };
  }
}

Component.mount(Share, '.js-share');
