/**
 * Select
 */

import Component from 'js/lib/component';

export default class Select extends Component {
  init() {
    this.state = {};
    this.elements({
      field: '.js-select-field',
      placeholder: '.js-select-placeholder'
    });
  }
  events() {
    this.$.field.on('change', e => {
      e.stopPropagation();

      const $selected = this.$.root.find(':selected');
      const label = $selected.text();
      const value = $selected.val();

      this.$.placeholder.text(label);
      this.$.root.trigger('change', { label, value });
    });
  }
}

Component.mount(Select, '.js-select');
