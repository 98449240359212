/**
 * gallery
 */

import Component from 'js/lib/component';

import $ from 'jquery';
import 'lightgallery';

export default class Gallery extends Component {
  init() {
    this.gallery();
  }
  gallery() {
    const _options = {
      selector: '.js-gallery-image',
      download: false,
      counter: false,
      startClass: 'lg-fade',
      getCaptionFromTitleOrAlt: false,
      hideBarsDelay: 1000000000
    };

    this.$.root.lightGallery(_options);
  }
  events() {
    this.$.root.on('onAfterOpen.lg', () => {
      this.$.body.addClass('gallery-visible');
      this.bodyOverflowEnable();
    });

    this.$.root.on('onBeforeClose.lg', () => {
      this.$.body.removeClass('gallery-visible');
    });

    this.$.root.on('onCloseAfter.lg', () => {
      this.bodyOverflowDisable();
    });
  }
}

Component.mount(Gallery, '.js-gallery');
