import Component from 'js/lib/component';
import 'lightgallery';

export default class Review extends Component {
  init() {
    const _options = {
      selector: '.js-review-image',
      download: false,
      counter: false,
      startClass: 'lg-fade',
      getCaptionFromTitleOrAlt: false,
      hideBarsDelay: 1000000000
    };

    this.$.root.lightGallery(_options);
  }
  events() {}
  handler() {}

  static in($node) {
    return Boolean($node.find('.js-review').length);
  }
  static is($node) {
    return $node.is('.js-review');
  }
  static find($node) {
    return $node.find('.js-review');
  }
}

Component.mount(Review, '.js-review');
