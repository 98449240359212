import Component from "js/lib/component";

import Menu from "~/menu/menu";
import Page from "~/page/page";

import anime from "animejs";

class Sidebar extends Component {
  init() {
    this.state = {
      open: false,
    };

    this.elements({
      close: ".js-sidebar-close",
      stagger: "[data-stagger]",
    });
  }
  events() {
    this.$.root.on("click", () => {
      if (this.mq.tablet.matches) {
        this.show();
      }
    });

    this.$.close.on("click", () => {
      this.hide();
    });
  }
  show() {
    if ($(".js-sidebar").hasClass("is-open")) {
    } else {
      this.state = {
        open: false,
      };
      console.log(this.state.open);
      if (this.state.open) return Promise.resolve();
      Page.lockUI();

      return this.hideMobileMenu().then(() => {
        this.$.body.addClass("has-opened-menu");

        return Promise.all([
          this.slideIn(),
          Page.showOverlay(),
          Page.shiftPage(),
        ]).then(() => {
          Page.unlockUI();
        });
      });
    }
  }
  hide() {
    this.state = {
      open: false,
    };
    Page.lockUI();
    this.$.body.removeClass("has-opened-menu");
    return anime({
      targets: this.$.root.get(0),
      width: this.mq.mobile.matches ? 0 : 50,
      duration: 400,
      easing: "easeInOutCubic",
      complete: () => {
        $(".js-sidebar").removeClass("is-open");
       // $(".js-page-overlay").removeClass("page__overlay");
        $(".js-page-overlay").removeAttr("style");
        $(".js-sidebar").removeAttr("style");
        $(".js-page-wrapper").removeAttr("style");
        $(".body").removeClass("is-locked");
      },
    });
    Page.unlockUI();
  }
  slideIn() {
    if (this.state.open) return Promise.resolve();

    this.$.root.addClass("is-overflow");
    this.$.root.addClass("is-open");

    this.state.open = true;

    const windowWidth = this.$.window.width();

    return anime({
      targets: this.$.root.get(0),
      width: this.mq.mobile.matches ? windowWidth : 233,
      duration: 350,
      translateX: "0",
      easing: "easeOutCubic",
      complete: () => {
        this.$.root.removeClass("is-overflow");
        this.$.root.css({
          transform: "",
          width: "100vw",
        });

        this.nextTick().then(() => {
          return this.showStagger();
        });
      },
    }).finished;
  }
  slideOut() {
    if (!this.state.open) {
      this.$.root.removeAttr("style");
      return Promise.resolve();
    }

    this.$.root.addClass("is-overflow");
    this.state.open = false;

    return anime({
      targets: this.$.root.get(0),
      width: this.mq.mobile.matches ? 0 : 50,
      duration: 400,
      easing: "easeInOutCubic",
      complete: () => {
        this.$.root.removeClass("is-open");
        this.$.root.removeClass("is-overflow");
        this.$.root.removeAttr("style");
      },
    }).finished;
  }
  hideMobileMenu() {
    if (!this.mq.mobile.matches) return Promise.resolve();

    return anime({
      targets: this.$.root.get(0),
      translateX: "-100%",
      duration: 300,
      easing: "easeOutCubic",
    }).finished;
  }
  showMobileMenu() {
    if (!this.mq.mobile.matches) return Promise.resolve();

    return anime({
      targets: this.$.root.get(0),
      translateX: "0",
      duration: 300,
      easing: "easeOutCubic",
    }).finished;
  }
  showStagger() {
    this.$.stagger.css("transform", "translateY(20px)");

    return anime({
      targets: this.$.stagger.get(),
      opacity: 1,
      translateY: 0,
      easing: "easeOutCubic",
      duration: 250,
      delay: (el, i) => {
        return i * 50;
      },
      complete: () => {
        this.$.stagger.css("transform", "");
      },
    }).finished;
  }
  hideStagger() {
    return anime({
      targets: this.$.stagger.get(),
      opacity: 0,
      translateY: -20,
      easing: "easeOutCubic",
      duration: 250,
      delay: (el, i) => {
        return i * 50;
      },
      complete: () => {
        this.$.stagger.css("transform", "");
      },
    }).finished;
  }
  isOpen() {
    return this.state.open;
  }
  inArea($target) {
    return Boolean(
      $target.is(".js-sidebar") || $target.closest(".js-sidebar").length
    );
  }
}

export default Component.mount(Sidebar, ".js-sidebar", true);
