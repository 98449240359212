/**
 * content
 */

import $ from 'jquery';
import Component from 'js/lib/component';
import anime from 'animejs';

class Content extends Component {
  init() {
    this.state = {
      animating: false,
      $currentButton: null
    };

    this.elements({
      body: '.js-content-body',
      amount: '.js-content-amount',
      button: '.js-content-button'
    });

    this.state.$currentButton = this.$.button.filter('.is-active');
  }
  events() {
    this.$.button.on('click', e => this.handler(e));
  }
  handler(e) {
    e.preventDefault();

    if (this.state.animating) return;

    const $target = $(e.target);

    const url = $target.data('url');
    const targets = this.$.body.get(0);

    this.toggleActiveTrigger($target);
    this.state.animating = true;

    anime({
      targets,
      duration: 500,
      translateX: 20,
      easing: 'easeInCubic',
      opacity: 0,
      complete: () => {
        const timeout = setTimeout(() => {
          this.$.root.addClass('is-loading');
        }, 400);

        this.$.body.css({ transform: 'translateX(-20px)' });

        this.load(url)
          .then(() => {})
          .fail(() => {
            console.error("Can't get content. Check your connection");
          })
          .always(() => {
            clearTimeout(timeout);
            this.$.root.removeClass('is-loading');

            anime({
              targets,
              duration: 400,
              translateX: 0,
              easing: 'easeOutCubic',
              opacity: 1,
              complete: () => {
                this.state.animating = false;
              }
            });
          });
      }
    });
  }
  load(url) {
    return $.ajax(url, {cache: false}).then(({ content, amount }) => {
      this.$.body.html(content);
      this.$.amount.text(amount);

      return content;
    });
  }
  toggleActiveTrigger($current) {
    this.state.$currentButton.removeClass('is-active');
    this.state.$currentButton.addClass('is-hollow');

    $current.removeClass('is-hollow');
    $current.addClass('is-active');

    this.state.$currentButton = $current;
  }
}

export default Component.mount(Content, '.js-content', true);
