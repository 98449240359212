/**
 * contact-form
 */

import Component from 'js/lib/component';

export default class ContactForm extends Component {
  init() {
    this.elements({
      select: '.js-contact-form-select'
    });
  }
  events() {
    this.$.select.on('change', (e, data) => {
      const { label, value } = data;

      location = value;
    });
  }
}

Component.mount(ContactForm, '.js-contact-form');
