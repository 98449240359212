/**
 * tabs
 */

import Component from 'js/lib/component';

import $ from 'jquery';
import anime from 'animejs';

export default class Tabs extends Component {
  init() {
    this.elements({
      tab: '.js-tabs-item',
      label: '.js-tabs-label',
      inner: '.js-tabs-inner'
    });

    this.$.tab.hide();
    this.$.root.addClass('is-inited');
    this.setActive();
  }
  events() {
    this.$.label.on('click', e => this.handler(e));
  }
  handler(e) {
    e.preventDefault();
    e.stopPropagation();

    const $target = $(e.currentTarget);

    this.href = $target.attr('href');
    this.setActive();
  }
  setActive() {
    this.setLabel();
    this.setTab();
  }
  setLabel() {
    this.$.label.removeClass('is-active');
    this.$.label.addClass('is-hollow');

    this.$label.removeClass('is-hollow');
    this.$label.addClass('is-active');
  }
  setTab() {
    const $current = this.$current;
    const $next = this.$tab;

    if ($current.length) {
      this.toggleTab($current, $next);
    } else {
      $next.addClass('is-active');
      $next.show();
    }
  }
  toggleTab($current, $next) {
    this.fixHeight();

    anime({
      targets: $current.get(0),
      translateX: 30,
      opacity: 0,
      easing: 'easeInCubic',
      duration: 400,
      complete: () => {
        $current.removeClass('is-active').css({ display: 'none' });
        $next.addClass('is-active').css({
          display: 'block',
          opacity: 0,
          transform: 'translateX(-30px)'
        });

        this.updateHeight();

        anime({
          targets: $next.get(0),
          translateX: 0,
          opacity: 1,
          easing: 'easeOutCubic',
          duration: 400
        });
      }
    });
  }
  set href(href) {
    location.hash = href;
  }
  get href() {
    return location.hash || '';
  }
  get $tab() {
    const $filtered = this.$.tab.filter(this.href);
    return $filtered.length ? $filtered : this.$.tab.first();
  }
  get $current() {
    return this.$.tab.filter('.is-active');
  }
  get $label() {
    const $filtered = this.$.label.filter(`[href="${this.href}"]`);
    return $filtered.length ? $filtered : this.$.label.first();
  }
  fixHeight() {
    const height = this.$.inner.height();
    this.$.inner.height(height);
  }
  updateHeight() {
    const height = this.$current.height();

    anime({
      targets: this.$.inner.get(0),
      duration: 400,
      easing: 'easeOutCubic',
      height,
      complete: () => {
        this.$.inner.removeAttr('style');
      }
    });
  }
}

Component.mount(Tabs, '.js-tabs');
