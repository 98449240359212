/**
 * tooltip
 */

import $ from 'jquery';
import Component from 'js/lib/component';
import anime from 'animejs';

/* global location */

/** TODO:
 *  раскоментировать host
 *  поменять метод на POST
 */

class Tooltip extends Component {
  init() {
    const host = '/product/'; //location.host;

    this.param = {
      animationSpeed: 400,
      delayBeforeOpen: 400,
      delayBeforeClose: 600
    };

    this.elements({
      link: `a[href*="${host}"]`
    });
  }
  events() {
    this.$.link.on('mouseenter', e => this.handler(e));
  }
  handler(e) {
    if (this.timer) {
      clearTimeout(this.timer);
    }

    const $target = $(e.target);

    if ($target.is('.has-tooltip')) return;

    $target.addClass('is-hover');
    $target.one('mouseleave', () => {
      $target.removeClass('is-hover');
    });

    this.timer = setTimeout(() => {
      if (!$target.is('.is-hover')) return;

      this.sendRequest($target).then(data => {
        const $card = $(this.render(data));

        $target.one('mouseleave', () => {
          setTimeout(() => {
            anime({
              targets: $card.get(0),
              opacity: 0,
              translateY: -10,
              duration: this.param.animationSpeed,
              easing: 'easeInCubic',
              complete: () => {
                $card.remove();
                $target.removeClass('has-tooltip');
              }
            });
          }, this.param.delayBeforeClose);
        });

        $card.css({
          opacity: 0,
          transform: 'translateY(-10px)'
        });

        $target.append($card);
        $target.addClass('has-tooltip');

        anime({
          targets: $card.get(0),
          opacity: 1,
          translateY: 0,
          duration: this.param.animationSpeed,
          easing: 'easeOutCubic'
        });
      });
    }, this.param.delayBeforeOpen);
  }
  sendRequest($target) {
    const url = $target.attr('href');

    return new Promise((resolve, reject) => {
      if (!$target.is('.is-hover')) {
        reject();
      }

      $.ajax(url, {
        method: 'POST'
      })
        .then(data => {
          resolve(data);
        })
        .fail(() => {
          reject();
        });
    });
  }
  render({ image, title, descr }) {
    return `
      <div class="tooltip">
        <a class="tooltip__cover">
          <div class="tooltip__image" style="background-image: url(${image})"></div>
        </a>
        <h4 class="tooltip__title">${title}</h4>
        <p class="tooltip__descr">${descr}</p>
      </div>
    `;
  }
}

export default Component.mount(Tooltip, '.js-page', true);
