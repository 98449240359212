/**
 * nav
 */

import $ from 'jquery';
import 'jquery.pubsub';

import Component from 'js/lib/component';

export default class Navigation extends Component {
  init() {
    this.state = {
      focus: false
    };

    this.elements({
      trigger: '.js-nav-toggle',
      dropmenu: '.js-nav-drop'
    });

    $.on('header.fixed', () => this.closeOpened());
  }
  events() {
    this.$.document.on('click', e => this.handler(e));
    this.$.window.on('keydown', e => this.handler(e));
    this.$.trigger.on('mouseenter', e => this.handler(e));
  }
  handler(e) {
    const methods = {
      click: this.click,
      mouseenter: this.mouseenter,
      keydown: this.keydown
    };

    const type = e.type;
    const method = methods[type];

    method.call(this, e);
  }
  click(e) {
    const $target = $(e.target);

    if ($target.is(this.$.trigger)) {
      e.preventDefault();

      const $drop = $target.siblings().filter(this.$.dropmenu);
      this.state.focus = true;

      if ($target.is('.is-active')) {
        this.state.focus = false;
        this.close($target, $drop);

        return;
      }

      this.closeOpened();
      this.open($target, $drop);
    } else {
      if (!$target.closest(this.$.dropmenu).length) {
        this.state.focus = false;
        this.closeOpened();
      }
    }
  }
  mouseenter(e) {
    if (!this.state.focus) return;

    const $target = $(e.target);
    const $drop = $target.siblings().filter(this.$.dropmenu);

    if (!this.isActive($target)) {
      this.closeOpened();
      this.open($target, $drop);
    }
  }
  keydown(e) {
    if (e.keyCode === 27) {
      this.closeOpened();
    }
  }
  isActive($target) {
    return $target.is('.is-active');
  }
  open($target, $drop) {
    $target.addClass('is-active');
    $drop.show().addClass('is-open');
  }
  close($target, $drop) {
    $target.removeClass('is-active');
    $drop.hide().removeClass('is-open');
  }
  closeOpened() {
    this.$.trigger.removeClass('is-active');
    this.$.dropmenu
      .filter('.is-open')
      .hide()
      .removeClass('is-open');
  }
}

Component.mount(Navigation, '.js-nav');
