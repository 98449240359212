import $ from 'jquery';
import _filter from 'lodash/_baseFilter.js';

export const components = {};

let id = 0;

export default class Component {
  constructor(element, selector = '') {
    this.selector = selector;
    this.name = this.constructor.name;

    this.methods = {};

    this.breakpoint = {
      lg: 1599,
      xl: 1439,
      l: 1179,
      md: 959,
      m: 767,
      s: 559,
      xs: 320
    };

    this.mq = {
      desktop: matchMedia('(min-width: 1365px)'),
      tablet: matchMedia('(max-width: 1365px)'),
      mobile: matchMedia('(max-width: 560px)')
    };

    this.$ = {
      window: $(window),
      document: $(document),
      body: $('.js-body'),
      page: $('.js-page'),
      pageInner: $('.js-page-inner'),
      pageWrapper: $('.js-page-wrapper'),
      root: $(element)
    };

    if (this.$.root.length) {
      this.init();
      this.events();
      this.inited();
    }
  }
  init() {
    console.warn(`Need create init method for "${this.selector}"`);
  }
  elements(elements) {
    for (let element in elements) {
      this.$[element] = this.$.root.find(elements[element]);
    }
  }
  events() {
    // no events
  }
  getId() {
    return ++id;
  }
  handler(e) {
    const type = e.data.type;
    const method = this.methods[type];

    if (method) {
      this.methods[type].call(this, e);
    } else {
      console.warn('Method undefined');
    }
  }
  inited() {}
  bodyOverflowEnable() {
    this.$.body.css({ paddingRight: this.getScrollbarWidth() });
    this.$.body.addClass('is-overflow');
  }
  bodyOverflowDisable() {
    this.$.body.removeAttr('style');
    this.$.body.removeClass('is-overflow');
  }
  getScrollbarWidth() {
    return window.innerWidth - this.$.document.width();
  }
  removeRoot() {
    this.$.root.html('').remove();
    delete this.$.root;
  }
  nextTick() {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve();
      }, 0);
    });
  }
  log(message, param) {
    console.log(
      `%c${Date.now()}: %c${message} ${param ? param : ''}`,
      'color: #999',
      'color: #000'
    );
  }
  get isDesktop() {
    return this.$.window.outerWidth() > this.breakpoint.l;
  }
  get isTablet() {
    return (
      this.$.window.outerWidth() <= this.breakpoint.l &&
      this.$.window.outerWidth() > this.breakpoint.s
    );
  }
  get isMobile() {
    return this.$.window.outerWidth() <= this.breakpoint.s;
  }
  getState() {
    return this.state || {};
  }
  static mount(Constructor, selector, isSingleton) {
    const $elements = $(selector);

    if (!Boolean($elements.length)) return {};

    const init = element => {
      const id = Math.random()
        .toString(16)
        .slice(2);

      element.selector = selector;
      element.componentId = id;

      return {
        id,
        component: new Constructor(element, selector)
      };
    };

    if (isSingleton) {
      const element = $elements.get(0);
      const { id, component } = init(element);

      components[id] = component;

      return component;
    } else {
      $elements.map((i, element) => {
        const { id, component } = init(element);
        components[id] = component;
      });
    }
  }
  static initComponent(Cons, $node) {
    if (Cons.in($node) || Cons.is($node)) {
      const $element = Cons.is($node) ? $node : Cons.find($node);
      const ids = [];

      $element.each((i, element) => {
        const id = Math.random()
          .toString(16)
          .slice(2);

        element.selector = Cons.selector;
        element.componentId = id;

        components[id] = new Cons(element, Cons.selector);
        ids.push(id);
      });

      return ids;
    }

    return null;
  }
  static getAllComponentInstance(name) {
    return _filter(
      components,
      component => component.name.toLowerCase() === name.toLowerCase()
    );
  }
  static getInstances($element, selector) {
    return $element
      .find(selector)
      .map((i, component) => components[component.componentId]);
  }
  static isFunction(fn) {
    return fn && typeof fn === 'function';
  }
}

components['get'] = name => Component.getAllComponentInstance(name);

window.components = components;
