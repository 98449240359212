import Component from 'js/lib/component';

export default class Search extends Component {
  init() {
    this.state = {
      open: false
    };
    this.elements({
      open: '.js-search-open',
      close: '.js-search-close',
      field: '.js-search-field'
    });
  }
  events() {
    this.$.open.on('click', () => this.open());
    this.$.close.on('click', () => this.close());
  }
  open() {
    this.$.field.css('display', 'flex');
    this.$.open.hide();
    this.$.body.addClass('has-open-search');
    this.state.open = true;
  }
  close() {
    this.$.field.hide();
    this.$.open.show();
    this.$.body.removeClass('has-open-search');
    this.state.open = false;
  }
}

Component.mount(Search, '.js-search');
