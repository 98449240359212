/**
 * scroll
 */

import Component from 'js/lib/component';

import $ from 'jquery';
import anime from 'animejs';

export default class Scroll extends Component {
  init() {
    this.animating = false;
    this.rellaxDisabled = true;

    this.elements({
      anchors: '[data-scroll-to]'
    });

    this.methods = {
      scrollTo: this.scrollToHandler
    };
  }
  events() {
    this.$.anchors.on('click', { type: 'scrollTo' }, e => this.handler(e));
  }
  scrollToHandler(e) {
    e.preventDefault();

    if (this.animating) return false;

    const $current = $(e.target);
    const $target = $($current.attr('href'));

    this.scrollTo($target);
  }

  scrollTo($target, done) {
    if (!$target.length) return;

    this.animating = true;

    const offset = $target.offset().top;
    const targets = $('html, body').get();
    const duration = 800;

    anime({
      targets,
      scrollTop: offset,
      duration,
      easing: 'easeInOutCubic',
      complete: () => {
        if (done) done();
        this.animating = false;
      }
    });
  }
}

Component.mount(Scroll, '.js-body');
