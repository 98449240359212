/**
 * city-select
 */

import $ from 'jquery';
import Component from 'js/lib/component';

export default class CitySelect extends Component {
  init() {
    this.state = {
      open: false
    };

    this.className = {
      isOpen: 'is-open',
      isCurrent: 'is-current'
    };

    this.elements({
      placeholder: '.js-city-select-placeholder',
      list: '.js-city-select-list',
      item: '.js-city-select-item'
    });
  }
  events() {
    this.$.placeholder.on('click', () => this.toggle());

    /// this.$.item.on('click', e => {
    //   e.preventDefault();
    //
    //   const $target = $(e.target);
    //   const city = $target.data('city');
    //
    //   this.setPlaceholder(city);
    //   this.setCurrent($target);
    //   this.close();
    // });

    this.$.document.on('click', e => {
      const $target = $(e.target);
      const $closest = $target.closest('.js-city-select');

      if (!$closest.length) {
        this.close();
      }
    });
  }
  toggle() {
    if (this.state.open) {
      this.close();
    } else {
      this.open();
    }
  }
  open() {
    this.$.list.fadeIn(200);
    this.$.root.addClass(this.className.isOpen);
    this.state.open = true;
  }
  close() {
    this.$.list.fadeOut(200);
    this.$.root.removeClass(this.className.isOpen);
    this.state.open = false;
  }
  setPlaceholder(label) {
    this.$.placeholder.text(label);
  }
  setCurrent($current) {
    this.$.item.removeClass(this.className.isCurrent);
    $current.addClass(this.className.isCurrent);
  }
}

Component.mount(CitySelect, '.js-city-select');
