/**
 * slider
 */

import Component from 'js/lib/component';

import $ from 'jquery';
import 'slick-carousel';

export default class Slider extends Component {
  set(elements, options) {
    const { slider = {} } = this.$.root.data('options') || {};

    this.elements(elements);

    this.config = $.extend({}, slider, options);

    this.$.slider.on('init', () => this.mount());
    this.$.root.removeAttr('data-options');

    this.slick();
  }
  slick() {
    const _default = {
      accessibility: false,
      arrows: true,
      dots: false,
      infinite: true,
      speed: 600,
      prevArrow: this.$.prev,
      nextArrow: this.$.next
    };

    this.$.slider.slick($.extend({}, _default, this.config));
  }
  mount() {
    this.show();
  }
  show() {
    return new Promise(resolve => {
      this.$.root.addClass('is-inited');
      this.$.root.one('transitionend', () => resolve());
    });
  }
}
