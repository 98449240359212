/**
 * modal
 */

import Component from 'js/lib/component';

import Page from '~/page/page';
import Map from '~/map/map';
import Menu from '~/menu/menu';

import $ from 'jquery';
import VanillaModal from 'vanilla-modal';

class Modal extends Component {
  init() {
    this.state = {
      isMap: false
    };

    this.modal = new VanillaModal({
      modal: '.modal',
      modalInner: '.modal__inner',
      modalContent: '.modal__content',
      loadClass: 'modal-init',
      onBeforeOpen: () => {
        Page.showOverlay();
      },
      onBeforeClose: () => {
        Page.hideOverlay();

        this.nextTick().then(() => {
          this.$.root.one('transitionend', () => {
            if (this.state.isMap) {
              this.state.isMap = false;
              this.$.body.removeClass('has-opened-map');
            }
          });
        });
      }
    });

    this.elements({
      title: '.js-modal-title',
      header: '.js-modal-header',
      content: '.js-modal-content'
    });

    this.$.modal = $('.js-default-modal');
  }
  events() {
    this.$.document.on('click', '[data-modal-close]', () => this.close());
    this.$.document.on('click', '[data-modal]', e => this.handler(e));
  }
  handler(e) {
    e.preventDefault();

    const $target = $(e.currentTarget);
    const data = $target.data('modal');

    if (typeof data === 'object') {
      this.initMap(data, $target);
      return;
    }

    if (name.indexOf('/') === -1) {
      const modalId = `#modal-${data}`;
      const title = $(modalId).data('title') || null;
      this.setTitle(title);
      this.open(modalId);
    } else {
      const timeout = setTimeout(() => {
        $target.addClass('is-loading');
      }, 800);
      this.getContent(data)
        .then(() => {
          this.open('#modal');
          this.onTransition().then(() => {
            clearTimeout(timeout);
            $target.removeClass('is-loading');
          });
        })
        .fail(err => {
          clearTimeout(timeout);
          $target.removeClass('is-loading');
          throw new Error(err);
        });
    }
  }
  getContent(url) {
    return $.ajax(url).then(({ title, content }) => {
      this.setContent(content);
      this.setTitle(title);
    });
  }
  setContent(content) {
    if (this.$.modal.length) {
      this.$.modal.append(content);
    }
  }
  setTitle(title) {
    if (title) {
      this.$.header.show();
      this.$.title.text(title);
    } else {
      this.$.header.hide();
    }
  }
  initMap(data, $target) {
    this.state.isMap = true;

    const timeout = setTimeout(() => {
      $target.addClass('is-loading');
    }, 300);

    const open = () => {
      clearTimeout(timeout);

      $target.removeClass('is-loading');

      this.$.body.addClass('has-opened-map');
      this.open('#modal');
    };

    if (this.map) {
      this.setContent(this.map.$.container.children());
      this.map.update(data, () => open());
    } else {
      this.map = new Map();
      this.map.init(data, () => open());
    }
  }
  open(id) {
    Page.lockUI();

    if (this.isOpen()) {
      this.close().then(() => {
        this.modal.open(id);
        Page.unlockUI();
      });
    } else {
      Menu.getCurrent()
        .close()
        .then(() => {
          this.modal.open(id);
          Page.unlockUI();
        });
    }
  }
  close() {
    if (!this.isOpen()) return Promise.resolve();

    Page.lockUI();

    return new Promise(resolve => {
      setTimeout(() => {
        this.modal.close();

        return this.onTransition().then(() => {
          Page.unlockUI();
          resolve();
        });
      }, 400);
    });
  }
  onTransition() {
    return new Promise(resolve => {
      this.$.root.one('transitionend', () => {
        resolve();
      });
    });
  }
  isOpen() {
    return this.modal.isOpen;
  }
  inArea($target) {
    return $target.is('.js-modal') || $target.closest('.js-modal').length;
  }
}

export default Component.mount(Modal, '.js-modal', true);
