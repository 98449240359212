/**
 * reviews-roll
 */

import Component from 'js/lib/component';
import Slider from '~/slider/slider';
import Review from '~/review/review';

export default class ReviewsRoll extends Slider {
  init() {
    const elements = {
      slider: '.js-reviews-roll-cards',
      item: '.js-reviews-roll-card',
      prev: '.js-reviews-roll-prev',
      next: '.js-reviews-roll-next'
    };

    const options = {
      arrows: true,
      fade: false,
      swipe: true,
      slidesToShow: 6,
      dots: false,
      cssEase: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
      speed: 800,
      responsive: [
        {
          breakpoint: 1599,
          settings: {
            slidesToShow: 4
          }
        },
        {
          breakpoint: 959,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 560,
          settings: {
            slidesToShow: 2
          }
        }
      ]
    };

    this.set(elements, options);
  }
  events() {
    this.$.root.on('breakpoint', () => {
      Component.initComponent(Review, this.$.root);
    });
  }
  mount() {
    this.$.root.css({ opacity: 1 });
  }
}

Component.mount(ReviewsRoll, '.js-reviews-roll');
