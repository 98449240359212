import Component from 'js/lib/component';
import $ from 'jquery';

export default class Load extends Component {
  init() {
    this.elements({
      container: '.js-load-container',
      button: '.js-load-button'
    });
  }
  events() {
    this.$.button.on('click', e => {
      e.preventDefault();

      const $target = $(e.target);
      const $root = $target.closest(this.$.root);
      const $container = $root.find(this.$.container);

      const src = $target.data('src');
      const timer = setTimeout(() => {
        $target.addClass('is-loading');
      }, 2000);

      $.ajax(src, {
        method: 'GET'
      })
        .then(({ content, isLast }) => {
          const $content = $(content);

          $content.css({ opacity: 0 });
          $container.append($content);
          $content.animate({ opacity: 1 }, 200, () => {
            this.nextTick().then(() => {
              $content.removeAttr('style');
            });
          });

          if (isLast) {
            $target.remove();
          }
        })
        .always(() => {
          clearTimeout(timer);

          if ($target.is('.is-loading')) {
            $target.removeClass('is-loading');
          }
        });
    });
  }
}

Component.mount(Load, '.js-load');
