/**
 * objects-roll
 */

import Component from 'js/lib/component';
import Slider from '~/slider/slider';

export default class ObjectsRoll extends Slider {
  init() {
    const elements = {
      slider: '.js-objects-roll-cards',
      item: '.js-objects-roll-card',
      prev: '.js-objects-roll-prev',
      next: '.js-objects-roll-next'
    };

    const options = {
      arrows: true,
      fade: false,
      swipe: true,
      slidesToShow: 3,
      dots: false,
      cssEase: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
      speed: 800,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2
          }
        }
      ]
    };

    this.set(elements, options);
  }
  mount() {
    this.$.root.css({ opacity: 1 });
  }
}

Component.mount(ObjectsRoll, '.js-objects-roll');
