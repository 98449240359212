import Component from 'js/lib/component';
import Optiscroll from 'optiscroll';

export default class Scrollbar extends Component {
  init() {
    const element = this.$.root.get(0);

    new Optiscroll(element);

    this.$.body.addClass('scrollbar-init');
  }
}

Component.mount(Scrollbar, '.js-scrollbar');
