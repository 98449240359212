/**
 * posts-roll
 */

import $ from 'jquery';
import Component from 'js/lib/component';
import Slider from '~/slider/slider';

export default class PostsRoll extends Slider {
  init() {
    const elements = {
      slider: '.js-posts-roll-cards',
      item: '.js-posts-roll-card',
      prev: '.js-posts-roll-prev',
      next: '.js-posts-roll-next'
    };

    const options = {
      arrows: true,
      fade: false,
      swipe: true,
      slidesToShow: 4,
      dots: false,
      cssEase: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
      speed: 800,
      responsive: [
        {
          breakpoint: 1599,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 959,
          settings: {
            slidesToShow: 2
          }
        }
      ]
    };

    this.set(elements, options);
  }
  mount() {
    this.$.root.css({ opacity: 1 });
  }
}

Component.mount(PostsRoll, '.js-posts-roll');
