/**
 * events-slider
 */

import $ from 'jquery';
import Component from 'js/lib/component';
import Keyframes from 'js/lib/keyframes';
import Slider from '~/slider/slider';

export default class EventsSlider extends Slider {
  init() {
    const { animation = {} } = this.$.root.data('options') || {};

    const elements = {
      slider: '.js-events-slider-slides',
      slide: '.js-events-slider-slide',
      arrows: '.js-events-slider-arrows',
      prev: '.js-events-slider-prev',
      next: '.js-events-slider-next'
    };

    const options = {
      arrows: false,
      dots: false,
      fade: true,
      swipe: true,
      autoplay: true,
      autoplaySpeed: 4000,
      speed: 400
    };

    this.methods = {
      PREV: () => this.go('slickPrev'),
      NEXT: () => this.go('slickNext'),
      GOTO: e => {
        e.preventDefault();
        e.stopPropagation();

        const $dot = $(e.target);
        const index = $dot.data('index');

        this.go('slickGoTo', index);
      }
    };

    this.animation = {
      duration: animation.duration || 800,
      offset: animation.offset || 400,
      easing: animation.easing || 'easeOutQuad'
    };

    this.set(elements, options);

    const slick = this.$.slider.slick('getSlick');
    const slidesAmount = slick.$slides.length;

    if (slidesAmount < 2) {
      this.$.arrows.hide();
    }
  }
  events() {
    this.$.prev.on('click', { type: 'PREV' }, e => this.handler(e));
    this.$.next.on('click', { type: 'NEXT' }, e => this.handler(e));
  }
  go(dir, index) {
    this.out().then(() => {
      if (index !== undefined) {
        this.$.slider.slick(dir, index, true);
      } else {
        this.$.slider.slick(dir);
      }
      this.in();
    });
  }
  in() {
    return new Promise(resolve => {
      Keyframes.getTimeline(this.getSlide(), this.animation).complete = () => {
        resolve();
      };
    });
  }
  out() {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve();
      }, 100);
    });
  }
  getSlide(target) {
    const index =
      target !== undefined ? target : this.$.slider.slick('slickCurrentSlide');
    return this.$.slide.eq(index);
  }
  mount() {
    this.hide();
    this.show().then(() => {
      this.in();
    });
  }
  hide() {
    const $slide = this.getSlide(0);
    const $elements = $slide.find('[data-animate]');

    $elements.css({ opacity: 0 });
  }
}

Component.mount(EventsSlider, '.js-events-slider');
