/**
 * menu
 */

import Component from 'js/lib/component';

import Page from '~/page/page';

import $ from 'jquery';
import 'jquery.easing';
import anime from 'animejs';
import _filter from 'lodash/_baseFilter.js';

export default class Menu extends Component {
  init() {
    this.state = {
      sideActive: false,
      $currentButton: null,
      $currentGroup: null,
      $currentSubList: null,
      $currentLink: null
    };

    this.id = this.getId();

    this.elements({
      button: '.js-menu-button',
      group: '.js-menu-group',
      link: '.js-menu-link',
      sublistTitle: '.js-menu-sublist-title',
      sublist: '.js-menu-sublist',
      subitem: '.js-menu-subitem',
      side: '.js-menu-side',
      close: '.js-menu-close',
      toggle: '.js-menu-toggle',
      dropmenu: '.js-menu-dropmenu'
    });

    this.setCurrentMenu();
  }
  setCurrentMenu() {
    if (!this.$.button.length) return;

    const index = localStorage.getItem(`${this.name}${this.id}`);

    this.toggle(index < 0 ? 0 : index, true);
    this.$.root.css('opacity', 1);
  }
  toggle(index, force = false) {
    const $button = this.$.button.eq(index);
    const $group = this.$.group.eq(index);

    if (this.isCurrentGroup($group)) return;

    this.toggleButton($button);
    this.toggleGroup($group, force);
  }
  events() {
    this.$.button.on('click', e => {
      const $target = $(e.target);
      const index = $target.index();

      this.toggle(index);
    });

    this.$.link.on('click', e => {
      const $target = $(e.target);
      const $link = $target.is('.js-menu-link')
        ? $target
        : $target.closest('.js-menu-link');

      if (this.hasChildren($link)) {
        e.preventDefault();

        if (this.isActive($link)) {
          this.close();
        } else {
          this.open($link);
        }
      } else {
        if (this.mq.mobile.matches && this.state.sideActive) {
          e.preventDefault();
          this.close();
        }
      }
    });

    this.$.close.on('click', () => {
      this.close();
    });

    this.$.toggle.on('click', e => {
      this.toggleDropMenu(e);
    });
  }
  getTarget(e) {
    const className = '.js-menu-toggle';
    const $target = $(e.target);

    if ($target.is(className)) {
      return $target;
    } else {
      const $closest = $target.closest(className);
      const $sibling = $target.siblings(className);

      return $closest.length ? $closest : $sibling;
    }
  }
  toggleDropMenu(e) {
    const $target = this.getTarget(e);
    const $dropmenu = $target.siblings(this.$.dropmenu);

    if ($dropmenu.length) {
      e.preventDefault();

      if ($dropmenu.is('.is-open')) {
        $target.removeClass('is-active');
        $dropmenu.slideUp(300, 'easeOutCubic').removeClass('is-open');
      } else {
        $target.addClass('is-active');
        $dropmenu.slideDown(300, 'easeOutCubic').addClass('is-open');
      }
    }
  }
  isCurrentGroup($group) {
    return Boolean($group.is('.is-current'));
  }
  toggleButton($button) {
    if (!this.$.button.length) return;

    if (this.state.$currentButton === null) {
      this.state.$currentButton = this.$.button.first();
    }

    localStorage.setItem(`${this.name}${this.id}`, $button.index());

    this.state.$currentButton.removeClass('is-current');
    $button.addClass('is-current');
    this.state.$currentButton = $button;
  }
  toggleGroup($group, force) {
    if (this.state.$currentGroup === null) {
      this.state.$currentGroup = this.$.group.first();
    }

    if (force) {
      $group.addClass('is-current');
      $group.css('opacity', 1);
      this.state.$currentGroup.removeClass('is-current');
      this.state.$currentGroup = $group;
    } else {
      Page.lockUI();

      const height = {
        menu: this.$.root.height(),
        currentGroup: this.state.$currentGroup.height(),
        nextGroup: $group.height()
      };

      this.$.root.height(height.menu);

      return this.close().then(() => {
        this.$.root.addClass('is-clipped');

        $group.addClass('is-current');
        $group.css({
          opacity: 0,
          transform: 'translateX(-100%)'
        });

        height.menu = height.menu - height.currentGroup + height.nextGroup;

        const animationOut = anime({
          targets: this.state.$currentGroup.get(0),
          duration: 700,
          opacity: 0,
          translateX: '100%',
          easing: 'easeInOutQuart'
        }).finished;

        const animationIn = anime({
          targets: $group.get(0),
          duration: 700,
          opacity: 1,
          translateX: 0,
          easing: 'easeInOutQuart'
        }).finished;

        const animationMenuHeight = anime({
          targets: this.$.root.get(0),
          duration: 600,
          height: height.menu,
          easing: 'easeInOutQuart',
          complete: () => {}
        }).finished;

        return Promise.all([
          animationOut,
          animationIn,
          animationMenuHeight
        ]).then(() => {
          this.$.root.removeClass('is-clipped');
          this.state.$currentGroup.removeClass('is-current');

          $group.css({
            transform: ''
          });

          this.state.$currentGroup = $group;
          Page.unlockUI();

          return Promise.resolve();
        });
      });
    }
  }
  open($link) {
    Page.lockUI();

    this.setActive($link);

    return Promise.all([
      Page.shiftPage(),
      Page.showOverlay(),
      this.openSide(),
      this.showList($link)
    ]).then(() => {
      Page.unlockUI();
    });
  }
  close() {
    if (!this.state.sideActive || this.state.$currentSubList === null) {
      return Promise.resolve();
    }

    Page.lockUI();

    this.unsetActive();

    /*eslint-disable */
    return !this.mq.tablet.matches
      ? Promise.all([
          Page.hideOverlay(),
          Page.unshiftPage(),
          this.closeSide(),
          this.hideList()
        ]).then(() => {
          Page.unlockUI();
        })
      : Promise.all([this.closeSide(), this.hideList()]).then(() => {
          Page.unlockUI();
        });
    /*eslint-enable */
  }
  openSide() {
    if (this.state.sideActive) return Promise.resolve();

    Page.lockUI();

    this.$.side.css({
      display: 'block',
      opacity: this.mq.mobile.matches ? 0 : 1,
      transform: `translateX(${this.mq.mobile.matches ? '20px' : '-100%'})`
    });

    return this.nextTick().then(() => {
      anime({
        targets: this.$.side.get(0),
        duration: 350,
        easing: 'easeOutCubic',
        opacity: 1,
        translateX: 0,
        complete: () => {
          this.state.sideActive = true;
          Page.unlockUI();
        }
      }).finished;
    });
  }
  closeSide() {
    if (!this.state.sideActive) return Promise.resolve();

    Page.lockUI();

    return anime({
      targets: this.$.side.get(0),
      duration: 350,
      delay: 40,
      opacity: this.mq.mobile.matches ? 0 : 1,
      easing: 'easeInCubic',
      translateX: this.mq.mobile.matches ? '20' : '-100%',
      complete: () => {
        this.$.side.css({
          display: 'none'
        });

        this.state.sideActive = false;
        Page.unlockUI();
      }
    }).finished;
  }
  setActive($link) {
    $link.addClass('is-active');
    this.unsetActive();
    this.state.$currentLink = $link;
  }
  unsetActive() {
    if (this.state.$currentLink !== null) {
      this.state.$currentLink.removeClass('is-active');
      this.state.$currentLink = null;
    }
  }
  setTitle($link) {
    if (this.$.sublistTitle.length) {
      const title = $link.text();
      this.$.sublistTitle.text(title);
    }
  }
  isActive($link) {
    return Boolean($link.is('.is-active'));
  }
  showList($link) {
    const $sublist = this.getSubList($link);

    if (this.state.$currentSubList) {
      return this.toggleSublist($sublist, $link);
    } else {
      Page.lockUI();

      $sublist.css({
        display: 'block',
        opacity: 0,
        transform: `translateX(${this.mq.mobile.matches ? '20px' : '-100%'})`
      });

      this.setTitle($link);

      return this.nextTick().then(() => {
        return anime({
          targets: $sublist.get(0),
          duration: 350,
          delay: 60,
          easing: 'easeOutCubic',
          translateX: 0,
          opacity: 1,
          complete: () => {
            Page.unlockUI();
            this.state.$currentSubList = $sublist;
          }
        }).finished;
      });
    }
  }
  hideList() {
    if (!this.state.$currentSubList) return Promise.resolve();

    Page.lockUI();

    return anime({
      targets: this.state.$currentSubList.get(0),
      duration: 350,
      easing: 'easeInCubic',
      translateX: this.mq.mobile.matches ? '20' : '-100%',
      opacity: 0,
      complete: () => {
        this.state.$currentSubList.css('display', 'none');
        this.state.$currentSubList = null;

        Page.unlockUI();
      }
    }).finished;
  }
  toggleSublist($sublist, $link) {
    if (!this.state.$currentSubList) return Promise.resolve();

    Page.lockUI();

    return anime({
      targets: this.state.$currentSubList.get(0),
      duration: 300,
      opacity: 0,
      translateX: '20px',
      easing: 'easeInOutCubic',
      complete: () => {
        this.state.$currentSubList.css({
          display: 'none',
          translateX: '0px',
          opacity: 1
        });

        $sublist.css({
          opacity: 0,
          transform: 'translateX(-20px)',
          display: 'block'
        });

        this.setTitle($link);
        this.state.$currentSubList = $sublist;
      }
    }).finished.then(() => {
      return this.nextTick().then(() => {
        return anime({
          targets: $sublist.get(0),
          duration: 300,
          opacity: 1,
          translateX: '0px',
          easing: 'easeInOutCubic',
          complete: () => {
            Page.lockUI();
          }
        }).finished;
      });
    });
  }
  getSubList($link) {
    return $link.siblings().filter(this.$.sublist);
  }
  hasChildren($link) {
    return Boolean($link.siblings(this.$.sublist).length);
  }
  static getAllInstances() {
    return Component.getAllComponentInstance('Menu');
  }
  static getCurrent() {
    const instnces = Menu.getAllInstances();
    const current = _filter(instnces, menu => menu.state.sideActive)[0];

    return current || instnces[0];
  }
  static isOpen() {
    return Boolean(Menu.getCurrent());
  }
}

Component.mount(Menu, '.js-menu');
