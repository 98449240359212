import $ from 'jquery';

import 'js/lib/svg';
import 'js/lib/hello';
import 'js/lib/focus';
import 'js/lib/scroll';
import 'js/lib/scrollbar';
import 'js/lib/load';
import 'js/lib/touch';

import '~/sidebar/sidebar';
import '~/page/page';
import '~/city-select/city-select';
import '~/menu/menu';
import '~/nav/nav';
import '~/events-slider/events-slider';
import '~/posts-roll/posts-roll';
import '~/totop/totop';
import '~/content/content';
import '~/tabs/tabs';
import '~/gallery/gallery';
import '~/review/review';
import '~/objects-roll/objects-roll';
import '~/reviews-roll/reviews-roll';
import '~/share/share';
import '~/map/map';
import '~/contact-form/contact-form';
import '~/input/select';
import '~/modal/modal';
import '~/tooltip/tooltip';
import '~/search/search';

/* Add jQuery to global scope */
window.jQuery = window.$ = $;
