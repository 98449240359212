import Component from 'js/lib/component';

import Sidebar from '~/sidebar/sidebar';
import Modal from '~/modal/modal';
import anime from 'animejs';
import $ from 'jquery';

class Page extends Component {
  init() {
    this.state = {
      locked: false,
      shifted: false,
      hasOverlay: false,
      sidebarIsOpen: false,
      animating: false
    };

    this.elements({
      inner: '.js-page-inner',
      sidebar: '.js-page-sidebar',
      wrapper: '.js-page-wrapper',
      overlay: '.js-page-overlay'
    });
  }
  events() {
    this.$.page.on('click', e => {
      if (this.state.locked) return;

      const $target = $(e.target);

      if (!Sidebar.inArea($target)) {
        if (!Modal.inArea($target)) {
          Sidebar.hide();
        }
      }

      if (!Modal.inArea($target)) {
        Modal.close();
      }
    });

    this.$.window.on('keydown', e => {
      if (this.state.locked) return;

      if (e.keyCode === 27) {
        Sidebar.hide();
        Modal.close();
      }
    });

    this.mq.tablet.addListener(() => {
      Sidebar.hide();
    });
  }
  showOverlay() {
    if (this.state.hasOverlay) return Promise.resolve();

    this.$.overlay.css({
      display: 'block',
      opacity: 0
    });

    this.lockUI();

    return this.nextTick().then(() => {
      anime({
        targets: this.$.overlay.get(0),
        duration: 300,
        opacity: 0.7,
        easing: 'easeOutCubic',
        complete: () => {
          this.unlockUI();
          this.state.hasOverlay = true;
        }
      }).finished;
    });
  }
  hideOverlay() {
    if (!this.state.hasOverlay) return Promise.resolve();

    this.lockUI();

    if (Sidebar.isOpen() || Modal.isOpen()) {
      return Promise.resolve();
    }

    return anime({
      targets: this.$.overlay.get(0),
      duration: 300,
      opacity: 0,
      easing: 'easeInCubic',
      complete: () => {
        this.$.overlay.css({
          display: 'none'
        });
        this.state.hasOverlay = false;
        this.unlockUI();
      }
    }).finished;
  }
  shiftPage() {
    if (this.state.shifted) return Promise.resolve();

    this.lockUI();

    return anime({
      targets: this.$.wrapper.get(0),
      duration: 400,
      translateX: 100,
      easing: 'easeOutCubic',
      complete: () => {
        this.unlockUI();
        this.state.shifted = true;
      }
    }).finished;
  }
  unshiftPage() {
    if (!this.state.shifted) return Promise.resolve();

    this.lockUI();

    return anime({
      targets: this.$.wrapper.get(0),
      duration: 400,
      translateX: 0,
      easing: 'easeInOutCubic',
      complete: () => {
        this.$.inner.removeAttr('style');
        this.unlockUI();
        this.state.shifted = false;
      }
    }).finished;
  }
  lockUI() {
    if (this.state.locked) return;

    this.$.body.addClass('is-locked');
    this.state.locked = true;
  }
  unlockUI() {
    if (!this.state.locked) return;

    this.$.body.removeClass('is-locked');
    this.state.locked = false;
  }
}

export default Component.mount(Page, '.js-page', true);
