/**
 * Map
 */

import $ from 'jquery';
import Component from 'js/lib/component';

/* global google */

export default class Map extends Component {
  init(data = null, ready) {
    this.param = {};
    this.collections = [];

    if (window.google) {
      this.setParams(data);
      this.initMap(ready);
    } else {
      $.getScript(
        '//maps.googleapis.com/maps/api/js?key=AIzaSyCGp6pOLSkRpcCD_AGJ4c9dlRZaHcuT9gs',
        () => {
          this.setParams(data);
          this.initMap(ready);
        }
      );
    }
  }
  update(data, ready) {
    this.setParams(data);
    this.removeAllMarkers();
    this.generateMarkers();
    this.setCenter(this.param.center);
    this.setZoom(this.param.zoom);

    if (Component.isFunction(ready)) ready();
  }

  setParams(data) {
    const param = data || this.$.root.data('options');

    this.markers = param.markers || [];
    this.param.center = param.center || [54.765961, 32.033613];
    this.param.zoom = param.zoom || 15;
    this.param.container = param.container || '.js-map-container';
  }
  initMap(ready) {
    this.$.container = $(`${this.param.container}`);
    this.$.container.html('');

    const options = {
      zoom: this.param.zoom,
      scrollwheel: false,
      center: new google.maps.LatLng(...this.param.center),
      disableDefaultUI: true,
      zoomControl: true,
      styles: [
        {
          stylers: [
            {
              hue: '#2c3e50'
            },
            {
              saturation: 40
            }
          ]
        },
        {
          featureType: 'road',
          elementType: 'geometry',
          stylers: [
            {
              lightness: 50
            },
            {
              visibility: 'simplified'
            }
          ]
        },
        {
          featureType: 'road',
          elementType: 'labels',
          stylers: [
            {
              visibility: 'on'
            }
          ]
        }
      ]
    };

    this.map = new google.maps.Map(this.$.container.get(0), options);
    this.generateMarkers();

    if (Component.isFunction(ready)) ready();
  }
  generateMarkers() {
    this.markers.forEach(marker => {
      this.setMarker(marker.address, marker.coords, {
        url: marker.image || '/img/marker.svg',
        size: new google.maps.Size(78, 109),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(39, 71),
        scaledSize: new google.maps.Size(78, 109)
      });
    });
  }
  setMarker(title, coords, icon) {
    const marker = new google.maps.Marker({
      position: new google.maps.LatLng(...coords),
      map: this.map,
      icon,
      optimized: false,
      title
    });

    this.collections.push(marker);
  }
  setCenter(coords) {
    this.map.setCenter({ lat: Number(coords[0]), lng: Number(coords[1]) });
  }
  setZoom(value) {
    this.map.setZoom(value);
  }
  removeAllMarkers() {
    this.collections.forEach(marker => marker.setMap(null));
    this.collections = [];
  }
}

Component.mount(Map, '.js-map');
